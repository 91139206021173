import revive_payload_client_6Tcgw0BFi5 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_u77qlBY4Mc from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bZ0jChM54G from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_l4gN7K24uB from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YZQ5N8EBpF from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_qsa32048HY from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_524PrmMRFl from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.31.0_typescript@5.0.4_vue@3.5.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/concierge/.nuxt/components.plugin.mjs";
import prefetch_client_kzGlbeVN3g from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.31.0__v5ususfke4uob36vbrg2ctqeje/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_BhfgOiDJpm from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.31.0_vue@3.5.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_nnhI8s1QML from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.31.0_vue@3.5.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vue_easy_lightbox_709h0kYieC from "/app/apps/concierge/src/modules/runtime/vue-easy-lightbox.ts";
import hubble_lB3djeYGL7 from "/app/apps/concierge/src/plugins/hubble.js";
import sentry_sCeXubc6q1 from "/app/apps/concierge/src/plugins/sentry.js";
export default [
  revive_payload_client_6Tcgw0BFi5,
  unhead_u77qlBY4Mc,
  router_bZ0jChM54G,
  payload_client_l4gN7K24uB,
  navigation_repaint_client_YZQ5N8EBpF,
  chunk_reload_client_qsa32048HY,
  plugin_vue3_524PrmMRFl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kzGlbeVN3g,
  switch_locale_path_ssr_BhfgOiDJpm,
  i18n_nnhI8s1QML,
  vue_easy_lightbox_709h0kYieC,
  hubble_lB3djeYGL7,
  sentry_sCeXubc6q1
]